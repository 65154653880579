import { FC, useEffect, useState } from 'react';

import { Translate, useTranslation } from '@cms/i18n';
import { Match, MatchPeriod, MatchStatus, PlatformID } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { DateTimeUtil, Format } from '@common/utils/DateTimeUtil';
import { getMatchTime } from '@common/utils/matchUtil';

import styles from './StatusElement.module.scss';

export interface Props {
    match: Match;
    isHorizontal?: boolean;
    showDynamicDateTime?: boolean;
    isMatchTicker?: boolean;
}

const isToday = (matchDate: Date): boolean => {
    const today = new Date();
    return (
        matchDate.getDate() === today.getDate() &&
        matchDate.getMonth() === today.getMonth() &&
        matchDate.getFullYear() === today.getFullYear()
    );
};

const getFormattedMatchDate = (
    dateString: string,
    isMatchTicker: boolean = false,
    __datetime: Translate,
): string => {
    const date: Date = new Date(dateString);
    const dateDayBefore = new Date(new Date().setDate(date.getDate() - 1));

    if (isMatchTicker) {
        if (isToday(date)) {
            return DateTimeUtil.format(date, Format.TIME_NUMERIC);
        } else {
            return DateTimeUtil.format(date, Format.DATE_NUMERIC_SHORT);
        }
    }

    if (isToday(date)) {
        return __datetime`today` + ' ' + DateTimeUtil.format(date, Format.TIME_NUMERIC);
    } else if (isToday(dateDayBefore)) {
        return __datetime`tomorrow` + ' ' + DateTimeUtil.format(date, Format.TIME_NUMERIC);
    } else {
        return DateTimeUtil.format(date, Format.DATE_DAY_MONTH);
    }
};

const calculateMsUntilNextMinute = () => {
    const now = Date.now();
    const msInMinute = 60000;
    return msInMinute - (now % msInMinute);
};

export const StatusElement: FC<Props> = ({ match, isHorizontal, isMatchTicker, showDynamicDateTime }) => {
    const { platform } = useContextData();
    const __datetime = useTranslation('datetime').t;
    const __livescores = useTranslation('livescores').t;
    const [_minutes, setMinutes] = useState(Date.now());

    const classes = ['status-element'];
    classes.push(styles.StatusElement);

    if (isMatchTicker === true) classes.push(styles['is-block']);
    if (isHorizontal) classes.push(styles['is-horizontal']);

    // Update every minute
    useEffect(() => {
        if (
            match.status &&
            [MatchStatus.CANCELLED, MatchStatus.SUSPENDED, MatchStatus.POSTPONED, MatchStatus.PLAYED].indexOf(
                match.status,
            ) !== -1
        ) {
            return;
        }

        const updateMinutes = () => setMinutes(Date.now());
        const msUntilNextMinute = calculateMsUntilNextMinute();
        const timeout = setTimeout(() => {
            updateMinutes();
            const interval = setInterval(updateMinutes, 60000);
            return () => clearInterval(interval);
        }, msUntilNextMinute);
        return () => clearTimeout(timeout);
    }, [match.status]);

    switch (match.status) {
        case MatchStatus.CANCELLED:
            return <span className={classes.join(' ')}>{__livescores`match.canceled`}</span>;
        case MatchStatus.SUSPENDED:
            return <span className={classes.join(' ')}>{__livescores`match.suspended`}</span>;
        case MatchStatus.POSTPONED:
            return <span className={classes.join(' ')}>{__livescores`match.postponed`}</span>;
    }

    switch (match.period) {
        case MatchPeriod.FINISHED:
            return (
                <span className={classes.join(' ')}>
                    <span className={styles['red-dot']} />
                    FT
                </span>
            );
        case MatchPeriod.NOT_STARTED:
            return (
                <span className={classes.join(' ')}>
                    {showDynamicDateTime
                        ? getFormattedMatchDate(match.kickOff, isMatchTicker, __datetime)
                        : DateTimeUtil.format(match.kickOff, Format.TIME_NUMERIC)}
                </span>
            );
        case MatchPeriod.FIRST_BREAK:
        case MatchPeriod.SECOND_BREAK:
        case MatchPeriod.THIRD_BREAK:
        case MatchPeriod.FOURTH_BREAK:
            return (
                <span className={classes.join(' ')}>
                    {platform.id === PlatformID.VI ? (
                        <>
                            <span className={styles['yellow-dot']} />{' '}
                        </>
                    ) : (
                        <span className={styles['ring-container']}>
                            <span className={styles.ring} />
                            <span className={styles.circle} />
                        </span>
                    )}
                    {__livescores`match.rest`}
                </span>
            );
        default:
            let time: number | string = 'FT';
            let overtime: number = 0;
            if (match.startOfCurrentPeriod && match.period) {
                const startOfCurrentPeriodDate = new Date(match.startOfCurrentPeriod);
                const timeObject = getMatchTime(0, match.period, startOfCurrentPeriodDate);
                time = timeObject.minutes;
                overtime = timeObject.overtime;
            }

            return (
                <span className={classes.join(' ')}>
                    <span className={styles['ring-container']}>
                        <span className={styles.ring} />
                        <span className={styles.circle} />
                    </span>

                    {match.period === MatchPeriod.PENALTIES ? (
                        <span>{__livescores`match.penalties`}</span>
                    ) : (
                        <span>
                            {time}&rsquo;
                            {overtime ? <sup> +{overtime}</sup> : ''}
                        </span>
                    )}
                </span>
            );
    }
};
